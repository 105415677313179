import './base';
import './common';
import initFooter from './footer';
import './flex';
import './grid';
import './margin';
import './position';
import './settings-menu';
import initHeader from './header';

export default () => {
    initHeader();
    initFooter();
}
